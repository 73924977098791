import React, { useEffect, useRef, useState } from 'react';
import Header from '../UI/Header/Header';
import Footer from '../UI/Footer/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import CustomToast from '../../Toast';
import $, { isEmptyObject } from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import Loader from '../UI/Loader';
import moment from 'moment';
import { Add_Address_Api, Add_Cart_Api, Add_Money_Wallet_Api, Add_Wishlist_Api, Clear_Wishlist_Api, Delete_Address_Api, Get_Login_User_Api, Get_Order_Api, Get_Wishlist_Data_Api, Update_Address_Api, Update_Login_User_Api, Wallet_Payment_Api } from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
    let { params } = useParams();
    const navigate = useNavigate();
    const [Profiledata, setProfiledata] = useState([]);
    const [Wishlistdata, setWishlistdata] = useState([]);
    const [walletPayments, setWalletPayments] = useState([]);
    const formRef = useRef(null);
    const token = localStorage.getItem('authToken');
    const addformRef = useRef(null);
    const addWalletRef = useRef(null);
    const walletFormRef = useRef(null);
    const editAddformRef = useRef(null);
    const [userData, setUserData] = useState({});
    const [address, setAddress] = useState({});
    const [orders, setOrders] = useState([]);
    const [isJain, setIsJain] = useState({});
    const [viewOrders, setViewOrders] = useState({});
    const [PinCodeLimit, setPinCodeLimit] = useState('');
    const [PinCodeUpdateLimit, setPinCodeUpdateLimit] = useState('');
    const [IsLoad, setIsLoad] = useState(true);
    const [sauce, setSauce] = useState(null);
    let subTotal = 0;
    const AddToCartFunction = (e, id, isJain = false) => {
        let data = {
            product_id: id,
        }
        if (sauce != null) {
            data.addOns = [
                {
                    _id: sauce,
                    quantity: 1
                }
            ]
        }
        if (isJain) {
            data.isJain = isJain
        }
        var config = {
            method: 'post',
            url: Add_Cart_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: data
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    toast.success(response.data.msg);
                    $("#isJainModel .btn-close").click();
                    $("#AddOnsModel .btn-close").click();
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const selectJainModel = (item, type) => {
        setIsJain(item);
        if (type == 'jain') {
            window.$('#isJainModel').modal('show');
        } else {
            setSauce(null);
            window.$('#AddOnsModel').modal('show');
        }
    }
    const GetWishlistData = () => {
        var config = {
            method: 'get',
            url: Get_Wishlist_Data_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                setWishlistdata(response.data.data)
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
                setIsLoad(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        var config = {
            method: 'get',
            url: Get_Login_User_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                setProfiledata(response.data.data)
                setUserData(response.data.data)
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
                setIsLoad(false);
            })
            .catch((error) => {
                console.log(error);
            });
        GetWishlistData();
    }, []);
    useEffect(() => {
        if (!isEmptyObject(userData)) {
            var config = {
                method: 'get',
                url: Wallet_Payment_Api + userData.id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
                .then((response) => {
                    setWalletPayments(response.data.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [userData]);
    useEffect(() => {
        var config = {
            method: 'get',
            url: Get_Order_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                setOrders(response.data.data)
                setIsLoad(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    $(addWalletRef.current).validate({
        rules: {
            amount: {
                required: true
            }
        },
        messages: {
            amount: {
                required: 'please enter amount'
            }
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            // if (check_Null == false) {
            var config = {
                method: 'post',
                url: Add_Money_Wallet_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.encRequest) {
                        $('#encRequest').val(response.data.encRequest)
                        $('#access_code').val(response.data.accessCode)
                        $(walletFormRef.current).submit();
                        $("#amount").val();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            // } else {
            //     toast.error("Please enter 6 digit otp");
            // }
        }
    });
    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                first_name: "required",
                last_name: "required",
                email: {
                    required: true,
                    email: true,
                },
            },
            messages: {
                first_name: "Please enter a first name",
                last_name: "Please enter a last name",
                email: {
                    required: "Please enter a email",
                    email: "Please enter a valid email",
                },
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = $(form).serialize();
                var config = {
                    method: 'PUT',
                    url: Update_Login_User_Api,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${token}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.error == false) {
                            toast.success(response.data.msg);
                        } else {
                            toast.error(response.data.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    }, [])
    const ProfileHandleChange = (e) => {
        setProfiledata((Profiledata) => ({
            ...Profiledata,
            [e.target.name]: e.target.value,
        }));
    }

    const WishlistClear = (e) => {
        setIsLoad(true);
        e.preventDefault();
        var config = {
            method: 'delete',
            url: Clear_Wishlist_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                GetWishlistData();
                if (response.data.error == false) {
                    toast.success(response.data.msg);
                }
                setIsLoad(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    // Add New Address
    $(addformRef.current).validate({
        rules: {
            name: {
                required: true,
            },
            email: {
                required: true,
                email: true
            },
            house_number: {
                required: true,
            },
            address_line_1: {
                required: true,
            },
            pinCode: {
                required: true,
            },
        },
        messages: {
            name: {
                required: 'Please enter name',
            },
            email: {
                required: 'Please enter email',
                email: "Please enter valid email"
            },
            house_number: {
                required: 'Please enter house number',
            },
            address_line_1: {
                required: 'Please enter address',
            },
            pinCode: {
                required: 'Please enter pincode',
            },
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            var config = {
                method: 'post',
                url: Add_Address_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.error == false) {
                        toast.success(response.data.msg);
                        setUserData({ ...userData, address: response.data.data })
                        window.$("#addModal").offcanvas('hide');
                        $("#addAddressForm").trigger("reset");
                    } else {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });
    // get Address
    const getAddress = (e) => {
        let id = e.target.id;
        userData.address.map(item => {
            if (item._id == id) {
                setAddress(item);
            }
        })
    }
    // Edit New Address
    $(editAddformRef.current).validate({
        rules: {
            name: {
                required: true,
            },
            email: {
                required: true,
                email: true
            },
            house_number: {
                required: true,
            },
            address_line_1: {
                required: true,
            },
            pinCode: {
                required: true,
            },
        },
        messages: {
            name: {
                required: 'Please enter name',
            },
            email: {
                required: 'Please enter email',
                email: "Please enter valid email"
            },
            house_number: {
                required: 'Please enter house number',
            },
            address_line_1: {
                required: 'Please enter address',
            },
            pinCode: {
                required: 'Please enter pincode',
            },
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let id = $("#addressId").val();
            let n_form = $(form).serialize();
            var config = {
                method: 'put',
                url: Update_Address_Api + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.error == false) {
                        toast.success(response.data.msg);
                        setUserData({ ...userData, address: response.data.data })
                        setAddress({});
                        window.$("#AddressUpdateModal").offcanvas('hide');
                    } else {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });
    // Delete Address
    const deleteAddress = (e) => {
        let id = e.target.id;
        var config = {
            method: 'delete',
            url: Delete_Address_Api + id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    toast.success(response.data.msg);
                    setUserData({ ...userData, address: response.data.data })
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const AddTowishlistFunction = (e, id) => {
        var config = {
            method: 'get',
            url: Add_Wishlist_Api + id,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        };
        axios(config)
            .then((response) => {
                GetWishlistData();
                if (response.data.error == false) {
                    toast.success(response.data.msg);
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const PinCodeLimithandleChange = (event) => {
        const value = event.target.value;
        const onlyDigits = /^\d*$/;
        if (onlyDigits.test(value) && value.length <= 6) {
            setPinCodeLimit(value);
        }
    };
    const PinCodeUpdateLimithandleChange = (event) => {
        const value = event.target.value;
        const onlyDigits = /^\d*$/;
        if (onlyDigits.test(value) && value.length <= 6) {
            setPinCodeUpdateLimit(value);
            setAddress({ ...address, pinCode: event.target.value });
        }
    };
    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            {IsLoad == true ? <Loader /> : ''}
            {/* <form id="nonseamless" className='d-none' method="post" name="redirect" ref={walletFormRef} action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> */}
            <form id="nonseamless" className='d-none' method="post" name="redirect" ref={walletFormRef} action=" https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                <input id="encRequest" name="encRequest" readOnly /><input name="access_code" id="access_code" readOnly />
            </form>
            <Header fileName={'profile'} />
            <div className="person-data">
                <section className="person-detail">
                    <div className="container">
                        <button onClick={() => navigate('/')} className="back">Back to Home</button>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <ul className="tab-container nav nav-tabs">
                                    <li >
                                        <a className={`tab pro nav-link ${params == 'pro' ? 'active' : ''}`} data-bs-toggle="tab" href="#profile-detail">Profile</a>
                                    </li>
                                    <li>
                                        <a className={`tab order nav-link ${params == 'order' ? 'active' : ''}`} data-bs-toggle="tab" href="#order-detail">Order</a>
                                    </li>
                                    <li>
                                        <a className={`tab wallet nav-link ${params == 'wallet' ? 'active' : ''}`} data-bs-toggle="tab" href="#wallet-detail">Wallet</a>
                                    </li>
                                    <li>
                                        <a className={`tab add nav-link ${params == 'add' ? 'active' : ''}`} data-bs-toggle="tab" href="#add-detail">Address</a>
                                    </li>
                                    <li>
                                        <a className={`tab fav nav-link ${params == 'fav' ? 'active' : ''}`} data-bs-toggle="tab" href="#fav-detail">Favorite</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-9 col-12 mt-5 my-md-0 tab-content">

                                <div className={`tab-pane profile-detail ms-md-4 ${params == 'pro' ? 'active' : ''}`} id='profile-detail'>
                                    <div className="col-md-6">
                                        <div className="titles">
                                            <h5>MY PROFILE</h5>
                                        </div>
                                        <div className="heading">
                                            <form ref={formRef}>
                                                <div className="form-group newerror input-label-group">
                                                    <label>First name</label>
                                                    <input className='input-tag-design' type="text" name='first_name' onChange={ProfileHandleChange} value={Profiledata.first_name} placeholder="First name" />
                                                </div>
                                                <div className="form-group newerror input-label-group">
                                                    <label>Last name</label>
                                                    <input className='input-tag-design' type="text" name='last_name' onChange={ProfileHandleChange} value={Profiledata.last_name} placeholder="Last name" />
                                                </div>
                                                <div className="form-group newerror input-label-group">
                                                    <label>Mobile no</label>
                                                    <input className='input-tag-design' type="text" name='mobile_number' value={Profiledata.mobile_number} disabled placeholder="Mobile no" />
                                                </div>
                                                <div className="form-group newerror input-label-group">
                                                    <label>Email</label>
                                                    <input className='input-tag-design' type="text" name='email' onChange={ProfileHandleChange} value={Profiledata.email} placeholder="Email" />
                                                </div>
                                                <button className="update-btn mt-3">UPDATE</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                                <div className={`tab-pane order-detail ${params == 'order' ? 'active' : ''}`} id='order-detail'>
                                    <div className="titles">
                                        <h5>MY ORDER HISTORY</h5>
                                    </div>
                                    {orders.length > 0 ?
                                        orders.map((item, index) => {
                                            return (
                                                <div className="order-box" key={index}>
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="pic">
                                                                <img src={item.items[0].productData.previewImage ? item.items[0].productData.previewImage : "../../assets/images/small.png"} className="w-100" />
                                                                <div className="veg">
                                                                    <img src="../../assets/images/veg.png" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10 mt-4 my-md-0">
                                                            <div className="row justify-content-between">
                                                                <h6>{item.totalItems} Items</h6>
                                                                <h6>Total Paid:  {item.payment.status == 'Shipped' || item.payment.status == 'Success' || item.payment.status == 'Successful' ? item.payment.amount.toFixed(2) : 0}</h6>
                                                            </div>
                                                            <p>Classic Veg Buddy Combo, Value Combo: 1 Regular Margherita <br /> and 1 Garlic Bread</p>
                                                            <div className="row justify-content-between">
                                                                <p>ORDER #{item.source.order_id} | {moment(item.createdAt).format('dddd, MMM DD, yyyy, h:mm a')}</p>
                                                                {
                                                                    item.payments.type == 'COD' ? <h5>Delivered on {moment(item.createdAt).format('dddd, MMM DD, yyyy, h:mm a')}</h5> :
                                                                        item.order_status == 'Success' && (item.payment.status == 'Shipped' || item.payment.status == 'Success' ||  item.payment.status == 'Successful') ?
                                                                            <h5>Delivered on {moment(item.createdAt).format('dddd, MMM DD, yyyy, h:mm a')}</h5>
                                                                            : <h5>Your Order is {item.order_status == 'Success' ? item.payment.status : item.order_status}</h5>
                                                                }
                                                            </div>
                                                            {/* {console.log(item)} */}
                                                            <button className="view" data-bs-toggle="offcanvas" data-bs-target="#viewOrderModel" onClick={(e) => setViewOrders(item)} id={item._id}>View Details</button>
                                                            <button className="reorder" id={item._id}>REORDER</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        :
                                        <div>
                                            No Orders
                                        </div>
                                    }
                                </div>


                                <div className={`tab-pane wallet-detail ${params == 'wallet' ? 'active' : ''}`} id='wallet-detail'>
                                    <div className="row mb-4 my-md-0 justify-content-between">
                                        <div className="titles">
                                            <h5>MY WALLET HISTORY</h5>
                                        </div>
                                        <div className="balance">
                                            <h4>TOTAL BALANCE</h4>
                                            <h2>₹{userData.wallet}</h2>
                                        </div>
                                    </div>
                                    <form ref={addWalletRef} id='addWalletForm' className='d-flex'>
                                        <div className='input-label-group col-3'>
                                            <label>Amount</label>
                                            <input type="number" className="mb-0 input-tag-design" name='amount' id='amount' placeholder="Enter Amount" />
                                        </div>
                                        <div className='form-group mb-0 newerror input-label-group'>
                                            <button type='submit' className="add-money">ADD MONEY</button>
                                        </div>
                                    </form>
                                    <div className="transaction-detail mt-3">
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr className="title">
                                                    <th>TRANSACTIONS</th>
                                                    <th className="right">AMOUNT</th>
                                                    <th className="right">STATUS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    walletPayments.length > 0 ? walletPayments.map((item, index) => {
                                                        if (item.status == 'Successful' || item.status == 'Shipped') {
                                                            return (
                                                                <tr>
                                                                    <th>Added to wallet<br /><span>{moment(item.createdAt).format('DD MMM, h:mm A')} <br />Online : Transaction ID {item.tracking_id}</span></th>
                                                                    <th className="right"> + ₹{item.amount.toFixed(2)}</th>
                                                                    <th className="right text-success">Success</th>
                                                                </tr>
                                                            )
                                                        } else {
                                                            return (
                                                                <tr>
                                                                    <th>Added to wallet<br /><span>{moment(item.createdAt).format('DD MMM, h:mm A')} <br />Online : Transaction ID {item.tracking_id}</span></th>
                                                                    <th className="right">₹{item.amount.toFixed(2)}</th>
                                                                    <th className="right text-danger">{item.status}</th>
                                                                </tr>
                                                            )
                                                        }
                                                    }) :
                                                        <tr>
                                                            <td colSpan={3} className='text-center fw-bold py-2'>No Transaction Avilable</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className={`tab-pane add-detail ms-md-4 ${params == 'add' ? 'active' : ''}`} id='add-detail'>
                                    <div className="titles">
                                        <h5>MY ADDRESS</h5>
                                    </div>
                                    <div className="row">
                                        {isEmptyObject(userData) == false && userData.address ?
                                            userData.address.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="col-md-6" key={index}>
                                                            <div className="add-box">
                                                                <h4>{item.house_number}, {item.address_line_1}</h4>
                                                                <p>{item.pinCode}</p>
                                                                <h6 className='d-flex'>
                                                                    <button onClick={(e) => getAddress(e)} data-bs-target="#AddressUpdateModal" id={item._id} data-bs-toggle="offcanvas" style={{ color: "#096cb8" }}>Edit</button>
                                                                    | <span><button style={{ color: "#c82027" }} onClick={(e) => deleteAddress(e)} id={item._id}>Delete</button></span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            :
                                            <div className="col-md-6">
                                                <p>No Address Found</p>
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <button className="add-btn" data-bs-target="#addModal" data-bs-toggle="offcanvas">+ ADD NEW ADDRESS</button>
                                        </div>
                                    </div>
                                </div>

                                <div className={`tab-pane fav-detail ${params == 'fav' ? 'active' : ''}`} id='fav-detail'>
                                    <div className="row">
                                        <div className='col-12 text-end py-2'>
                                            <a style={{ fontSize: '16px', fontWeight: '600', lineHeight: '1.5', color: '#c82027' }} onClick={(e) => WishlistClear(e)}>clear wishlist</a>
                                        </div>
                                        {Wishlistdata.map((item, index) => {
                                            return (
                                                <div className="col-md-4 my-md-0" key={index}>
                                                    <div className="item-box mb-4">
                                                        <div className="pic">
                                                            <img src={item.product.previewImage ? item.product.previewImage : "../../assets/images/default_img.jpeg"} className="w-100" />
                                                            <div className="veg">
                                                                <img src="../../assets/images/veg.png" />
                                                            </div>
                                                            <div className="like">
                                                                <div className="like" style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                    AddTowishlistFunction(e, item.product._id)
                                                                }}>
                                                                    <img src="../../assets/images/like.png" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item-detail">
                                                            <div className="bottom justify-content-between">
                                                                <h4>₹{item.product.rate}</h4>
                                                                {/* <h5><span>42% OFF</span>₹599</h5> */}
                                                            </div>
                                                            <h4>{item.product.name}</h4>
                                                            <p>{item.product.descrition}</p>
                                                            <div className="add-cart d-flex justify-content-end">
                                                                <button className="cart-btn" onClick={(e) => { item.isJain ? selectJainModel(item, 'jain') : item.product.addOns.length > 0 ? selectJainModel(item, 'addOns') : AddToCartFunction(e, item._id) }}>Add to cart</button>
                                                                {/* <a className="btn order-btn text-dark ms-2" href='/cart'>Order now</a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

            <div className="profile-modal offcanvas offcanvas-end left fade" id="viewOrderModel" tabIndex="" role="dialog" aria-labelledby="viewOrderModelLabel2" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Order #{!isEmptyObject(viewOrders) ? viewOrders.source.order_id : ''}</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <div className="box">
                        <h6>Work On Fire</h6>
                        <p>{viewOrders?.outlet_location?.name}</p>
                    </div>
                    {viewOrders.tabType == "delivery" ? <div className="box">
                        <h6>Deliverd to</h6>
                        <p>{!isEmptyObject(viewOrders) ? viewOrders.customer.address1 + ', ' + viewOrders.customer.address2 : ''}</p>
                    </div> : ''}

                    <div className="box highlight">
                        <p>Delivered on Sat, Jun 3, 2023, 01:17 PM by Chopada Mitesh Harishbhai</p>
                    </div>
                    <div className="item-box">
                        <p>{viewOrders.items ? viewOrders.items.length : '0'} ITEMS</p>
                        {viewOrders.items && viewOrders.items.map((item, index) => {
                            subTotal += (item.productData.rate * item.quantity);
                            return (
                                <div className="option" key={index}>
                                    <h5>
                                        {item.productData.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />}
                                        <span>{item.productData.name} X {item.quantity}</span></h5>
                                    <h5>₹{item.productData.rate * item.quantity}</h5>
                                </div>
                            )
                        })}
                        <div className="Border-bottom-Design"></div>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td>Item Total</td>
                                    <td className="right">₹{parseFloat(subTotal).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Discount</td>
                                    <td className="right">₹0</td>
                                </tr>
                                <tr>
                                    <td>Taxes and Charges</td>
                                    <td className="right">₹{!isEmptyObject(viewOrders) ? parseFloat(viewOrders.tax).toFixed(2) : 0}</td>
                                </tr>
                                <tr>
                                    <td>Delivery Charges</td>
                                    <td className="right">₹{!isEmptyObject(viewOrders) ? typeof viewOrders.deliveryCharge == typeof undefined ? 0 : parseFloat(viewOrders.deliveryCharge).toFixed(2) : 0}</td>
                                </tr>
                                <tr>
                                    <td>Wok On Fire Wallet</td>
                                    <td className="right">₹0</td>
                                </tr>
                                <tr>
                                    <td>Online Pay</td>
                                    <td className="right">₹{!isEmptyObject(viewOrders) ? viewOrders.payments.type == 'COD' ? 0 : parseFloat(viewOrders.payment.amount).toFixed(2) : ''}</td>
                                </tr>
                                <tr className="total">
                                    <td className="total">BILL TOTAL</td>
                                    <td className="right total">₹{parseFloat(viewOrders.amount).toFixed(2)}</td>
                                </tr>
                                {/* <tr>
                                    <td className="total">TOTAL PAID</td>
                                    <td className="right total">₹{!isEmptyObject(viewOrders) ? viewOrders.payments.type == 'COD' ? 0 : parseFloat(viewOrders.payment.amount).toFixed(2) : ''}</td>
                                </tr>
                                <tr className="total">
                                    <td className="total">TOTAL PAYABLE</td>
                                    <td className="right total">₹{!isEmptyObject(viewOrders) ? viewOrders.payments.type == 'COD' ? parseFloat(viewOrders.amount).toFixed(2) : 0 : 0}</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="isJainModel" tabIndex="-1" aria-labelledby="isJainModelLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h1 className="modal-title fs-5" id="isJainModelLabel">{isJain.isNonVeg == true ? <img src="/assets/images/non-veg.png" /> : <img src="/assets/images/veg.png" />} {isJain.name}</h1>
                                <p style={{ paddingLeft: '20px' }} >₹{isJain.rate}</p>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From Preparation Style for {isJain.name} (optional)</p>
                            <div className='checkbox text-start mt-4 mb-2'>
                                <img src={isJain.isNonVeg == true ? "/assets/images/non-veg.png" : "../../assets/images/veg.png"} />
                                <input type="checkbox" name="isJain" id="isJain" />
                                <label htmlFor="isJain" style={{ fontSize: '14px', marginLeft: '10px' }}>Jain</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="w-100 cart" onClick={(e) => AddToCartFunction(e, isJain._id, $("#isJain").prop('checked'))}>Add To Cart</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="AddOnsModel" tabIndex="-1" aria-labelledby="AddOnsModelLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    {
                        isJain.product &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <div>
                                    <h1 className="modal-title fs-5" id="AddOnsModelLabel">{isJain.product.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />} {isJain.product.name}</h1>
                                    <p style={{ paddingLeft: '20px' }} >₹{isJain.product.rate}</p>
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From {isJain.product.addOns ? isJain.product.addOns[0].category.name : ''} for {isJain.product.name} (atleast {isJain.product.addOns ? isJain.product.addOns[0].category.minNoOfAddon : 1})</p>
                                {
                                    isJain.product.addOns && isJain.product.addOns.map((item, index) => {
                                        return (
                                            <div className='checkbox text-start mt-4 mb-2'>
                                                <img src={isJain.product.isNonVeg == true ? "../../assets/images/non-veg.png" : "../../assets/images/veg.png"} />
                                                <input type="checkbox" name={item.name == '' ? item.aliasName : item.name} id={item.name == '' ? item.aliasName : item.name} value={item._id} checked={sauce == (item._id)} onChange={(e) => { setSauce(e.target.value); $(".sauceSelect").addClass('d-none'); }} />
                                                <label htmlFor={item.name == '' ? item.aliasName : item.name} style={{ fontSize: '14px', marginLeft: '10px' }}>{item.name == '' ? item.aliasName : item.name}</label>
                                            </div>
                                        )
                                    })
                                }
                                <p className="text-danger sauceSelect d-none"> select atleast 1 sauce </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="w-100 cart" onClick={(e) => { sauce != null ? AddToCartFunction(e, isJain._id, false) : $('.sauceSelect').removeClass('d-none') }}>Add To Cart</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="address-modal offcanvas offcanvas-end" id="addModal" tabIndex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Add Address</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <p>Fill details below</p>
                    <form ref={addformRef} id="addAddressForm">
                        <div className="mb-3 input-label-group form-group">
                            <label>Name</label>
                            <input type="text" className='newerror mb-0' name='name' />
                        </div>
                        <div className="mb-3 input-label-group form-group">
                            <label>Email</label>
                            <input type="text" className='newerror mb-0' name='email' />
                        </div>
                        <div className="mb-3 input-label-group form-group">
                            <label>House No. / Apt. Name</label>
                            <input type="text" className='newerror mb-0' name='house_number' />
                        </div>
                        <div className="mb-3 input-label-group form-group">
                            <label>Address</label>
                            <input type="text" className='newerror mb-0' name='address_line_1' />
                        </div>
                        <div className="mb-3 input-label-group form-group">
                            <label>Pin Code</label>
                            <input type="number" className='newerror mb-0' name='pinCode' onChange={(e) => PinCodeLimithandleChange(e)} value={PinCodeLimit} />
                        </div>
                        <button type='submit' className="save-btn">SAVE & CONTINUE</button>
                    </form>
                </div>
            </div>
            <div className="address-modal offcanvas offcanvas-end" id="AddressUpdateModal" tabIndex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Update Address</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <p>Fill details below</p>
                    <form ref={editAddformRef} id="myForm">
                        <input type="hidden" id='addressId' value={!isEmptyObject(address) ? address._id : ''} />
                        <div className="mb-3 input-label-group">
                            <label>Name</label>
                            <input type="text" className='newerror mb-0' name='name' value={!isEmptyObject(address) ? address.name : ''} onChange={(e) => setAddress({ ...address, name: e.target.value })} placeholder="Name" />
                        </div>
                        <div className="mb-3 input-label-group">
                            <label>Email</label>
                            <input type="text" className='newerror mb-0' name='email' value={!isEmptyObject(address) ? address.email : ''} onChange={(e) => setAddress({ ...address, email: e.target.value })} placeholder="Email Address" />
                        </div>
                        <div className="mb-3 input-label-group">
                            <label>House No. / Apt. Name</label>
                            <input type="text" className='newerror mb-0' name='house_number' value={!isEmptyObject(address) ? address.house_number : ''} onChange={(e) => setAddress({ ...address, house_number: e.target.value })} placeholder="House No." />
                        </div>
                        <div className="mb-3 input-label-group">
                            <label>Address</label>
                            <input type="text" className='newerror mb-0' name='address_line_1' value={!isEmptyObject(address) ? address.address_line_1 : ''} onChange={(e) => setAddress({ ...address, address_line_1: e.target.value })} placeholder="Address" />
                        </div>
                        <div className="mb-3 input-label-group">
                            <label>Pin Code</label>
                            <input type="number" className='newerror mb-0' name='pinCode' value={!isEmptyObject(address) ? address.pinCode : ''} onChange={(e) =>
                                PinCodeUpdateLimithandleChange(e)
                            } placeholder="Pin Code" />
                        </div>
                        <button type='submit' className="save-btn">SAVE & CONTINUE</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Profile