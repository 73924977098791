import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import axios from 'axios';
import $ from 'jquery'
import CustomToast from '../../Toast';
import { Add_Cart_Api, Change_CartProduct_Que_Api, Check_OfferCode_Api, Clear_Cart_Api, Delete_Product_Cart_Api, Get_Cart_Api, Get_Login_User_Api, Order_With_Cod_Api, Order_With_Upi_Api, Update_Cart_Addons_Api, Check_Outlet_Active_Api, Change_Delivery_Type_Api, Get_Recommendation_Api } from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Cart = () => {
    const [data, setdata] = useState([]);
    const [RecommendationProduct, setRecommendationProduct] = useState([]);
    const [userData, setUserData] = useState({ address: [] });
    const [DeliveryType, setDeliveryType] = useState(localStorage.getItem('orderType') || "delivery");
    const [subtotal, setsubtotal] = useState(0);
    const [total, settotal] = useState(0);
    const [RestaurantLocation, setRestaurantLocation] = useState(JSON.parse(localStorage.getItem('location')));
    const [tax, settax] = useState(0);
    const [deliveryCharges, setDeliveryCharges] = useState('0%');
    const [deliveryChargesTotal, setDeliveryChargesTotal] = useState(0);
    const token = localStorage.getItem('authToken');
    const [Payment_Method, setPayment_Method] = useState('COD');
    const [SelectedAddress, setSelectedAddress] = useState();
    const [Discount, setDiscount] = useState(0);
    const [isJain, setIsJain] = useState({});
    const navigate = useNavigate();
    const [sauce, setSauce] = useState(null);
    const [singleCart, setSingleCart] = useState({});
    const [offerCode, setOfferCode] = useState({
        code: '',
        clicked: false
    });
    const GetData = async () => {
        var config = {
            method: 'get',
            url: Get_Cart_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        await axios(config)
            .then(async (response) => {
                let result = response.data.data;
                setdata(result.cart_data);
                setsubtotal(result.subtotal);
                settax(result.tax);
                setDeliveryCharges(result.deliveryCharges);
                setDeliveryChargesTotal(result.deliveryChargesTotal);
                settotal(result.total);
                setDiscount(0);
                if (offerCode.code != '' && offerCode.clicked == true) {
                    var config1 = {
                        method: 'post',
                        url: Check_OfferCode_Api,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                        },
                        data: { code: offerCode.code }
                    };
                    await axios(config1)
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.error == true) {
                                settotal(response.data.data.total);
                                settax(response.data.data.tax);
                                setDeliveryChargesTotal(response.data.data.deliveryChargesTotal);
                                setDiscount(0);
                                toast.error(response.data.msg);
                            } else {
                                settotal(response.data.data.total);
                                settax(response.data.data.tax);
                                setDeliveryChargesTotal(response.data.data.deliveryCharges);
                                setDiscount(response.data.data.discount);
                                // toast.success(response.data.msg);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        await axios({
            method: 'get',
            url: Get_Recommendation_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async (response) => {
            let result = response.data.data;
            setRecommendationProduct(result);
        })
            .catch((error) => {
                console.log(error);
            });
    }
    const CartClear = () => {
        var config = {
            method: 'delete',
            url: Clear_Cart_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                GetData()
                if (response.data.error == false) {
                    toast.success(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        var config = {
            method: 'get',
            url: Get_Login_User_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    setUserData(response.data.data);
                    setSelectedAddress(response.data.data.address[0]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        GetData();
    }, []);
    const changeQuentity = (e, type) => {
        let id = e.target.id;
        let value = parseInt(e.target.value);
        if (type == 'add') {
            value += 1;
        } else {
            value -= 1;
        }
        var config = {
            method: 'post',
            url: Change_CartProduct_Que_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                id: id,
                quentity: value
            }
        };
        axios(config)
            .then((response) => {
                let data1 = data.map(item => {
                    if (item._id == response.data.data._id) {
                        item.quantity = response.data.data.quantity;
                        return item;
                    } else {
                        return item;
                    }
                })
                setdata(data1);
                GetData();
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const delteProductCart = (e) => {
        let id = e.target.id;
        var config = {
            method: 'delete',
            url: Delete_Product_Cart_Api,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id }
        };
        axios(config)
            .then((response) => {
                GetData();
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const AddToCartFunction = (e, id) => {
        let isJain = $(`select[name="isJain_${id}"] option:selected`).val();
        if (typeof isJain == typeof undefined) {
            isJain = false;
        }
        let data = {
            product_id: id,
        }
        if (sauce != null) {
            data.addOns = [
                {
                    _id: sauce,
                    quantity: 1
                }
            ]
        }
        data.isJain = isJain
        var config = {
            method: 'post',
            url: Add_Cart_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: data
        };
        axios(config)
            .then((response) => {
                GetData();
                $("#isJainModel .btn-close").click();
                $("#AddOnsModel .btn-close").click();
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const OrderFunction = () => {
        let brachId = JSON.parse(localStorage.getItem('location')).id;
        var config = {
            method: 'post',
            url: Check_Outlet_Active_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            },
            data: {
                brachId: brachId
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.msg);
                } else {
                    let outlet_location = JSON.parse(localStorage.getItem('location'));
                    let Order_Data = {
                        subtotal: subtotal,
                        tax: tax,
                        total: total,
                        deliveryCharge: deliveryChargesTotal,
                        address: SelectedAddress || null,
                        code: offerCode.code,
                        brachId: brachId,
                        outlet_location: outlet_location
                    }
                    if (data[0]) {
                        if (Payment_Method == 'COD') {
                            var config = {
                                method: 'post',
                                url: Order_With_Cod_Api,
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    'Authorization': `Bearer ${token}`
                                },
                                data: Order_Data
                            };
                            axios(config)
                                .then((response) => {
                                    if (response.data.error == false) {
                                        // CartClear();
                                        setdata([]);
                                        setsubtotal('0');
                                        settax('0');
                                        settotal('0');
                                        toast.success(response.data.msg);
                                    } else {
                                        toast.error(response.data.msg);
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                        if (Payment_Method == 'Online_Payment') {
                            var config = {
                                method: 'post',
                                url: Order_With_Upi_Api,
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    'Authorization': `Bearer ${token}`
                                },
                                data: Order_Data
                            };
                            axios(config)
                                .then((response) => {
                                    if (response.data.error == false) {
                                        if (response.data.encRequest) {
                                            $('#encRequest').val(response.data.encRequest);
                                            $('#access_code').val(response.data.accessCode);
                                            $('#workingKey').val(response.data.workingKey);
                                            $('#nonseamless').submit();
                                        } else {
                                            toast.error(response.data.msg);
                                        }
                                    } else {
                                        toast.error(response.data.msg);
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    } else {
                        toast.error("Cart is empty");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const selectJainModel = (item, type) => {
        setIsJain(item);
        if (type == 'jain') {
            window.$('#isJainModel').modal('show');
        } else {
            setSauce(null);
            window.$('#AddOnsModel').modal('show');
        }
    }
    const updateCartData = (id) => {
        var config = {
            method: 'post',
            url: Update_Cart_Addons_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                id: id,
                addons: [
                    {
                        quantity: 1,
                        _id: sauce
                    }
                ]
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                } else {
                    toast.success(response.data.msg);
                    GetData();
                    setSauce(null);
                    setSingleCart({});
                    $("#updateAddOnsModel .btn-close").click()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const applyOffer = (e) => {
        e.preventDefault();
        if (offerCode.code == '') {
            toast.error('Provide Offer Code');
        } else {
            var config = {
                method: 'post',
                url: Check_OfferCode_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: { code: offerCode.code }
            };
            axios(config)
                .then((response) => {
                    console.log(response.data);
                    setOfferCode({ ...offerCode, clicked: true });
                    if (response.data.error == true) {
                        settotal(response.data.data.total);
                        settax(response.data.data.tax);
                        setDeliveryChargesTotal(response.data.data.deliveryChargesTotal);
                        setDiscount('');
                        toast.error(response.data.msg);
                    } else {
                        settotal(response.data.data.total);
                        settax(response.data.data.tax);
                        setDeliveryChargesTotal(response.data.data.deliveryCharges);
                        setDiscount(response.data.data.discount);
                        toast.success(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const DeliveryTypeHandleChange = async (val) => {
        setDeliveryType(val);
        localStorage.setItem('orderType', val);
        await axios({
            method: 'get',
            url: Get_Recommendation_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async (response) => {
            let result = response.data.data;
            setRecommendationProduct(result);
        })
            .catch((error) => {
                console.log(error);
            });
        if (token != null) {
            // setLoading(true);
            var config = {
                method: 'post',
                url: Change_Delivery_Type_Api,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    type: val
                }
            };
            axios(config)
                .then(async (response) => {
                    let result = response.data;
                    if (result.error == false) {
                        setRestaurantLocation(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    // setLoading(false);
                });
        }
    };
    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            <Header fileName={'cart'} cartCount={data.length} />
            <div className="cart-detail">
                <div className="container">
                    <button className="back">Back to Home</button>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="selected-item">
                                <div className="row justify-content-between">
                                    <h5>{data.length} Items you have selected</h5>
                                    <h6 style={{ cursor: 'pointer' }} onClick={() => { CartClear() }}>Clear All</h6>
                                </div>
                                <div className="cart-box">
                                    {data.map((item, index) => {
                                        return (
                                            <>
                                                <div className="box" key={index}>
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="pic">
                                                                <img src={item.product.previewImage !== null ? item.product.previewImage : "../../assets/images/default_img.jpeg"} className="w-100 rounded-4" />
                                                                <div className="veg">
                                                                    {item.product.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="detail">
                                                                <div className="row justify-content-between">
                                                                    <h6>{item.product.name}</h6>
                                                                    <h6>₹{item.product.rate}</h6>
                                                                </div>
                                                                <div className="row justify-content-between">
                                                                    <p>{item.product.description}</p>
                                                                    <div className="quantity">
                                                                        <button className="plus-btn" type="button" onClick={(e) => changeQuentity(e, 'remove')} id={item._id} value={item.quantity} disabled={item.quantity == 1 ? 'disabled' : ''} name="button">
                                                                            -
                                                                        </button>
                                                                        <input type="text" name="name" value={item.quantity} readOnly min={1} max={50} />
                                                                        <button className="minus-btn" type="button" name="button" onClick={(e) => changeQuentity(e, 'add')} value={item.quantity} id={item._id}>
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <h4>Type : <span>{item.isJain ? 'Jain' : 'Regular'}</span></h4>
                                                                {
                                                                    item.addOns && item.addOns.length > 0 ? <h4 className="mt-3" style={{ cursor: 'pointer' }} onClick={(e) => { setSingleCart(item); setSauce(item.addOns[0]._id); window.$('#updateAddOnsModel').modal('show'); }} >Customize</h4> : ''
                                                                }

                                                                <div className="row justify-content-end">
                                                                    <div>
                                                                        <button className="order-btn text-dark" id={item._id} onClick={(e) => delteProductCart(e)} style={{ backgroundColor: '#ffb81c' }}>Remove</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                    })}
                                </div>
                                <div className="suggestion-sec">
                                    <h2>Recommendation</h2>
                                    <div className="row">
                                        {RecommendationProduct.map((item, index) => {
                                            return (
                                                <div className="col-xl-4 col-lg-6 col-12" key={index}>
                                                    <div className="suggestion-box">
                                                        <div className="pic">
                                                            <img src={item.previewImage !== null ? item.previewImage : "../../assets/images/default_img.jpeg"} className="w-100" />
                                                            <div className="price">
                                                                ₹{item.rate}
                                                            </div>
                                                        </div>
                                                        <div className="detail">
                                                            <p>{item.name}</p>
                                                            <div className="add-cart d-flex justify-content-between">
                                                                <div className='col-7'>
                                                                    <button className="cart" onClick={(e) => { item.addons.length > 0 ? selectJainModel(item, 'addOns') : AddToCartFunction(e, item._id) }}>Add to cart</button>
                                                                </div>
                                                                {
                                                                    item.isJain ?
                                                                        <div className="form-floating col-5">
                                                                            <select name={`isJain_${item._id}`} defaultChecked={false} className='form-control' id="floatingInput">
                                                                                <option value="false">Regular</option>
                                                                                <option value="true">Jain</option>
                                                                            </select>
                                                                            <label htmlFor="floatingInput">Type</label>
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="heading">
                                <h4>Delivery Type</h4>
                                <div className="radio-btn cart-check-box">
                                    <input type="radio" id="specifyColor" name="Delivery" onChange={() => DeliveryTypeHandleChange('delivery')} checked={DeliveryType == 'delivery' ? true : false} className='me-1' />
                                    <label htmlFor="specifyColor">Delivery</label>
                                    <input type="radio" id="pickUp" name="Delivery" onChange={() => DeliveryTypeHandleChange('take_out')} checked={DeliveryType == 'take_out' ? true : false} className='ms-3 me-1' />
                                    <label htmlFor="pickUp">Pick up | Dine-in</label>
                                </div>
                            </div>
                            <div className="heading">
                                <h4>Restaurant Location</h4>
                            </div>
                            <div className='cart-location-box-wrapper'>
                                <h4><span>Restaurant : </span>{RestaurantLocation.name}</h4>
                                <h4 className='mt-2'><span>City : </span>{RestaurantLocation.city}</h4>
                            </div>

                            {localStorage.getItem('orderType') == 'delivery' ?
                                <>
                                    <div className="heading">
                                        <h4>Select Location</h4>
                                    </div>
                                    <div className="location-box">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="map-select">
                                                {userData.address !== undefined && userData.address.length > 0 ?
                                                    <>
                                                        <img src="../../assets/images/location.png" />
                                                        <div className="detail">
                                                            <h5>Current Address</h5>
                                                            <p>{SelectedAddress.house_number}, {SelectedAddress.address_line_1}, {SelectedAddress.city}</p>
                                                            {/* <a className="edit">Edit Address</a> */}
                                                        </div>
                                                    </>
                                                    :
                                                    <button className="change-add" type='button' onClick={() => navigate('/profile/add')}>Add Address</button>
                                                }

                                            </div>
                                            {userData.address !== undefined && userData.address.length > 0 ?
                                                <div className='w-100'>
                                                    <button className="change-add mt-3" style={{ float: "right" }} type='button' data-bs-toggle="modal" data-bs-target="#addressModel" >Change</button>
                                                </div>
                                                :
                                                ''
                                            }
                                        </div>
                                    </div>
                                </>
                                : ''}
                            <div className='mb-4'>
                                <div className="heading">
                                    <h4>Apply Offer Code</h4>
                                </div>
                                <div>
                                    <form onSubmit={applyOffer}>
                                        <div className='d-flex align-items-center'>
                                            <input type="text" name="code" id="code" className='form-control' value={offerCode.code} placeholder="Enter Offer Code" onChange={(e) => { setOfferCode({ ...offerCode, code: e.target.value }) }} />
                                            <button className='cart' style={{ borderRadius: '6px' }}>Apply</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="heading">
                                <h4>Payment Method</h4>
                            </div>
                            <div className="location-box">
                                <div className="row align-items-center justify-content-between d-flex" style={{ flexDirection: 'column' }}>
                                    <div className="payment-box w-100">
                                        <div className="checkbox1">
                                            <input type="radio" id="COD" name="payment_method" value="COD" className='me-3' defaultChecked={Payment_Method == 'COD' ? true : false} onChange={(e) => { setPayment_Method('COD') }} />
                                            <label htmlFor="COD">Cash On Delivery</label>
                                        </div>
                                        <img src="../../assets/images/COD.png" style={{ height: "30px" }} />
                                    </div>
                                    <div className="payment-box w-100">
                                        <div className="checkbox1">
                                            <input type="radio" id="Online_Payment" name="payment_method" value="Online_Payment" className='me-3' defaultChecked={Payment_Method == 'Online_Payment' ? true : false} onChange={(e) => { setPayment_Method('Online_Payment') }} />
                                            <label htmlFor="Online_Payment">UPI</label>
                                        </div>
                                        <img src="../../assets/images/ccAvenue.png" />
                                    </div>
                                    <button className="sel-off"></button>
                                </div>
                            </div>
                            <div className="heading">
                                <h4>Price Details</h4>
                            </div>
                            <div className="price-box">
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td>Sub Total</td>
                                            <td className="right">₹{parseFloat(subtotal).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Discount</td>
                                            <td className="right">-{Discount}</td>
                                        </tr>
                                        <tr>
                                            <td>Taxes and Charges</td>
                                            <td className="right">₹{parseFloat(tax).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Delivery Charges ({deliveryCharges})</td>
                                            <td className="right">₹{parseFloat(deliveryChargesTotal).toFixed(2)}</td>
                                        </tr>
                                        <tr className="total">
                                            <td className="total">Grand Total</td>
                                            <td className="total right">₹{parseFloat(total).toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="order" onClick={() => OrderFunction()} disabled={data.length == 0 ? true : false}>PLACE ORDER</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <div className="modal fade" id="addressModel" tabIndex="-1" aria-labelledby="addressModelLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" >
                    <div className="modal-content" style={{ borderRadius: "10px" }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="addressModelLabel">Filters</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {userData.address.map((item, index) => {
                                    return (
                                        <div className="col-md-6 cart-detail" key={index} style={{ cursor: "pointer" }} onClick={(e) => { setSelectedAddress(item) }}>
                                            <div className="location-box">
                                                <input type="radio" name="address" id={item._id} value={item._id} defaultChecked={SelectedAddress._id == item._id ? true : false} />
                                                <h3 className='mb-1'><b>Address :</b> {item.house_number + ' ' + item.address_line_1}</h3>
                                                <h4 className='mb-1'><b>City :</b> {item.city}</h4>
                                                <h5 className='mb-1'><b>PinCode :</b> {item.pinCode}</h5>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="isJainModel" tabIndex="-1" aria-labelledby="isJainModelLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h1 className="modal-title fs-5" id="isJainModelLabel">{isJain.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />} {isJain.name}</h1>
                                <p style={{ paddingLeft: '20px' }} >₹{isJain.rate}</p>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From Preparation Style for {isJain.name} (optional)</p>
                            <div className='checkbox text-start mt-4 mb-2'>
                                <img src={isJain.isNonVeg == true ? "../../assets/images/non-veg.png" : "../../assets/images/veg.png"} />
                                <input type="checkbox" name="isJain" id="isJain" />
                                <label htmlFor="isJain" style={{ fontSize: '14px', marginLeft: '10px' }}>Jain</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="w-100 cart" onClick={(e) => AddToCartFunction(e, isJain._id, $("#isJain").prop('checked'))}>Add To Cart</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="updateAddOnsModel" tabIndex="-1" aria-labelledby="updateAddOnsModelLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    {
                        singleCart.product &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <div>
                                    <h1 className="modal-title fs-5" id="updateAddOnsModelLabel">{singleCart.product.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />} {singleCart.product.name}</h1>
                                    <p style={{ paddingLeft: '20px' }} >₹{singleCart.product.rate}</p>
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From {singleCart.product.addOns ? singleCart.product.addOns[0].category.name : ''} for {singleCart.product.name} (atleast {singleCart.product.addOns ? singleCart.product.addOns[0].category.minNoOfAddon : 1})</p>
                                {
                                    singleCart.product.addOns && singleCart.product.addOns.map((item, index) => {
                                        return (
                                            <div className='checkbox text-start mt-4 mb-2' key={index}>
                                                <img src={singleCart.product.isNonVeg == true ? "../../assets/images/non-veg.png" : "../../assets/images/veg.png"} />
                                                <input type="checkbox" name={item.name == '' ? item.aliasName : item.name} id={item.name == '' ? item.aliasName : item.name} value={item._id} checked={sauce == (item._id)} onChange={(e) => { setSauce(e.target.value); $(".sauceSelect").addClass('d-none'); }} />
                                                <label htmlFor={item.name == '' ? item.aliasName : item.name} style={{ fontSize: '14px', marginLeft: '10px' }}>{item.name == '' ? item.aliasName : item.name}</label>
                                            </div>
                                        )
                                    })
                                }
                                <p className="text-danger sauceSelect d-none"> select atleast 1 sauce </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="w-100 cart" onClick={(e) => { sauce != null ? updateCartData(singleCart._id) : $('.sauceSelect').removeClass('d-none') }}>Update Item</button>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className="modal fade" id="AddOnsModel" tabIndex="-1" aria-labelledby="AddOnsModelLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h1 className="modal-title fs-5" id="AddOnsModelLabel">{isJain.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />} {isJain.name}</h1>
                                <p style={{ paddingLeft: '20px' }} >₹{isJain.rate}</p>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From {isJain.addons ? isJain.addons[0].category.name : ''} for {isJain.name} (atleast {isJain.addons ? isJain.addons[0].category.minNoOfAddon : 1})</p>
                            {
                                isJain.addons && isJain.addons.map((item, index) => {
                                    return (
                                        <div className='checkbox text-start mt-4 mb-2'>
                                            <img src={isJain.isNonVeg == true ? "../../assets/images/non-veg.png" : "../../assets/images/veg.png"} />
                                            <input type="checkbox" name={item.name == '' ? item.aliasName : item.name} id={item.name == '' ? item.aliasName : item.name} value={item._id} checked={sauce == (item._id)} onChange={(e) => { setSauce(e.target.value); $(".sauceSelect").addClass('d-none'); }} />
                                            <label htmlFor={item.name == '' ? item.aliasName : item.name} style={{ fontSize: '14px', marginLeft: '10px' }}>{item.name == '' ? item.aliasName : item.name}</label>
                                        </div>
                                    )
                                })
                            }
                            <p className="text-danger sauceSelect d-none"> select atleast 1 sauce </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="w-100 cart" onClick={(e) => { sauce != null ? AddToCartFunction(e, isJain._id, false) : $('.sauceSelect').removeClass('d-none') }}>Add To Cart</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cart