import React, { useEffect, useState } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import axios from 'axios';
import CustomToast from '../../Toast';
import $, { isEmptyObject } from 'jquery';
import 'jquery-validation';
import { Add_Cart_Api, Add_Wishlist_Api, Filter_Products_Api, Get_Category_By_Product_Data_Api, Get_Category_Data_Api, Get_Nearest_Outlet, Get_Product_Data_Api, Get_SuperCategory_By_Product_Data_Api, Get_SuperCategory_Data_Api, Get_Wishlist_Data_Api, Search_Products_Api, Get_MenuSuperCatagoryData_Api, Get_SuperCategory_By_Product_Or_Category_Data_Api, Get_Cart_Api, Change_CartProduct_Que_Api, Get_Slider_Api, Delete_Product_Cart_Api } from '../../api';
import Loader from '../UI/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import gsap from 'gsap';

const HomePage = () => {
    let { filter_id } = useParams();
    const navigate = useNavigate();
    const [data, setdata] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [LoaderTrue, setLoaderTrue] = useState(false);
    // const [msg, setmsg] = useState({ msg: '', status: '' });
    const [SuperCatagorydata, setSuperCatagorydata] = useState([]);
    // const [Catagorydata, setCatagorydata] = useState([]);
    const [MenuSuperCatagoryData, setMenuSuperCatagoryData] = useState([]);
    const [AllCatagorydata, setAllCatagorydata] = useState([]);
    const [activeClass, setactiveClass] = useState('All');
    // const [activeCatgaory, setactiveCatgaory] = useState('');
    const [Wishlistdata, setWishlistdata] = useState([]);
    const [FilterVallue, setFilterVallue] = useState("1");
    const [token, setToken] = useState(null);
    const [sauce, setSauce] = useState(null);
    const [isJain, setIsJain] = useState({});
    const [SelectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});
    const [SliderData, setSliderData] = useState([]);
    const [SliderHover, setSliderHover] = useState('');
    const [IsLoad, setIsLoad] = useState();
    const [searchText, setSearchText] = useState(null);
    let subTotal = 0;
    let indexes = 0;
    // useEffect(() => {
    //     if ("geolocation" in navigator) {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    //             setUserLocation({
    //                 latitude: position.coords.latitude,
    //                 longitude: position.coords.longitude,
    //             });
    //         });
    //     } else {
    //         // console.log("Geolocation is not available in your browser.");
    //     }
    // }, []);
    // useEffect(() => {
    //     if (userLocation.latitude != null && userLocation.longitude != null) {
    //         axios({
    //             method: 'post',
    //             url: Get_Nearest_Outlet,
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             },
    //             data: userLocation,
    //         }).then(response => {
    //             let nearestLocation = response.data.data.nearestLocation;
    //             if (localStorage.getItem('location')) {
    //                 nearestLocation = JSON.parse(localStorage.getItem('location'));
    //                 setNearestOutlet(nearestLocation);
    //                 if (!isEmptyObject(nearestLocation)) {
    //                     let outlet = [];
    //                     SliderData.map((item, index) => {
    //                         if (item.outletType == nearestLocation.outletType) {
    //                             outlet.push(item);
    //                         }
    //                     });
    //                     // if (outlet.length > 0) {
    //                         // setSliderData(outlet);
    //                     // }
    //                 }
    //             } else {
    //                 setNearestOutlet(nearestLocation);
    //                 if (!isEmptyObject(nearestLocation)) {
    //                     let outlet = [];
    //                     SliderData.map((item, index) => {
    //                         if (item.outletType == nearestLocation.outletType) {
    //                             outlet.push(item);
    //                         }
    //                     });
    //                     // if (outlet.length > 0) {
    //                         // setSliderData(outlet);
    //                     // }
    //                 }
    //             }
    //         }).catch(err => {
    //             console.log(err);
    //         })
    //     }
    // }, [userLocation])

    function Get_Slider_Api_Function() {
        // console.log(nearestOutlet);
        axios({
            method: 'post',
            url: Get_Slider_Api,
            data: {
                store: nearestOutlet.id
            },
        }).then(response => {
            // let nearestLocation = JSON.parse(localStorage.getItem('location'));
            // // setNearestOutlet(nearestLocation);
            // if (!isEmptyObject(nearestLocation)) {
            //     let outlet = [];
            //     response.data.data.map((item, index) => {
            //         if (item.outletType == nearestLocation.outletType) {
            //             outlet.push(item);
            //         }
            //     });
            //     // if (outlet.length > 0) {
            //         setSliderData(outlet);
            //     // }
            // } else {
                setSliderData(response.data.data);
            // }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        Get_Slider_Api_Function();
    }, []);

    useEffect(() => {
        getCartData()
    }, [token])
    const getCartData = () => {
        if (token != null) {
            var config = {
                method: 'get',
                url: Get_Cart_Api,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
                .then((response) => {
                    let result = response.data.data;
                    setCartData(result.cart_data);
                    if (response.data.error == true) {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const GetData = () => {
        var config = {
            method: 'post',
            url: Get_Product_Data_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                store: nearestOutlet.id
            }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data)
                if (localStorage.getItem('authToken') != null) {
                    GetWishlistData();
                }
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                    setLoaderTrue(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const Get_MenuSuperCatagoryData = () => {
        var config = {
            method: 'get',
            url: Get_MenuSuperCatagoryData_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        };
        axios(config)
            .then((response) => {
                setMenuSuperCatagoryData(response.data.data);
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                    setLoaderTrue(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        // setTimeout(() => {
        if (localStorage.getItem('authToken') != null) {
            GetWishlistData();
        }
        var config = {
            method: 'get',
            url: Get_SuperCategory_Data_Api,
        };
        axios(config)
            .then((response) => {
                setSuperCatagorydata(response.data.data)
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                    setLoaderTrue(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        var config = {
            method: 'get',
            url: Get_Category_Data_Api,
        };
        axios(config)
            .then((response) => {
                setAllCatagorydata(response.data.data);
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                    setLoaderTrue(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // }, 3000);
        setToken(localStorage.getItem('authToken'));
    }, []);
    const GetWishlistData = () => {
        if (token != null) {
            var config = {
                method: 'get',
                url: Get_Wishlist_Data_Api,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            };
            axios(config)
                .then((response) => {
                    setWishlistdata(response.data.data)
                    if (response.data.error == true) {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    // useEffect(() => {
    //     Wishlistdata.forEach(async (i) => {
    //         let data1 = '';
    //         // if(data.length > 0){
    //             // if (!isEmptyObject(data[0].products)) {
    //             //     data1 = data[0].products.map((item, key) => {
    //             //         if (item._id == i.productId) {
    //             //             // if(item.islike){
    //             //             //     item.islike = false;
    //             //             // } else {
    //             //                 item.islike = true;
    //             //             // }
    //             //             return item
    //             //         } else {
    //             //             return item
    //             //         }
    //             //     })
    //             // } else {
    //                 data1 = await data.map((item, key) => {
    //                     console.log(item);
    //                     if(typeof item.products != typeof undefined){
    //                         if(item.products.length > 0){
    //                             item.products = item.products.map((pro, index) => {
    //                                 if (pro._id == i.productId) {
    //                                     // if(pro.islike){
    //                                     //     pro.islike = false;
    //                                     // } else {
    //                                         pro.islike = true;
    //                                     // }
    //                                     return pro
    //                                 } else {
    //                                     return pro
    //                                 }
    //                             })
    //                         }
    //                     } else {
    //                         if (item._id == i.productId) {
    //                             // if(item.islike){
    //                             //     item.islike = false;
    //                             // } else {
    //                                 item.islike = true;
    //                             // }
    //                             return item
    //                         } else {
    //                             return item
    //                         }
    //                     }
    //                 })
    //                 console.log(data1);
    //             // }
    //         // }
    //         // setdata(data1);
    //     })
    // }, [Wishlistdata])
    useEffect(() => {
        let data1 = data.map((item, key) => {
            if (typeof item.products != typeof undefined) {
                if (item.products.length > 0) {
                    let data2 = item.products.map((pro, index) => {
                        if (Wishlistdata.findIndex(x => x.productId == pro._id) >= 0) {
                            pro.islike = true;
                        } else {
                            pro.islike = false;
                        }
                        return pro;
                    })
                    data[key].products = data2;
                    return data[key];
                }
            } else {
                if (Wishlistdata.findIndex(x => x.productId == item._id) >= 0) {
                    item.islike = true;
                } else {
                    item.islike = false;
                }
                return item;
            }
        })
        setdata(data1);
    }, [Wishlistdata])
    const GetSuperCatagoryWiseData = (id) => {
        var config = {
            method: 'post',
            url: Get_SuperCategory_By_Product_Or_Category_Data_Api,
            data: {
                superCategoryId: id,
                storeId: nearestOutlet.id
            }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
                let active = SuperCatagorydata.filter(x => x.id == id);
                if (active.length > 0) {
                    setactiveClass(active[0].superCategoryName);
                }
                GetWishlistData();
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                    setLoaderTrue(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        if (typeof filter_id !== typeof undefined && filter_id != null) {
            GetSuperCatagoryWiseData(filter_id)
        } else {
            GetData();
        }
    }, [nearestOutlet, SuperCatagorydata])
    const AddToCartFunction = (e, id) => {
        if (localStorage.getItem('authToken')) {
            let isJain = $(`select[name="isJain_${id}"] option:selected`).val();
            if (typeof isJain == typeof undefined) {
                isJain = false;
            }
            let data = {
                product_id: id,
            }
            if (sauce != null) {
                data.addOns = [
                    {
                        _id: sauce,
                        quantity: 1
                    }
                ]
            }
            data.isJain = isJain
            var config = {
                method: 'post',
                url: Add_Cart_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    if (response.data.error == false) {
                        toast.success(response.data.msg);
                        $("#isJainModel .btn-close").click();
                        $("#AddOnsModel .btn-close").click();
                        getCartData();
                    } else {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            window.$('#AddOnsModel').modal('hide');
            window.$('#loginModal').offcanvas('show');
        }
    }
    const selectJainModel = (item, type) => {
        $(".sauceSelect").addClass('d-none');
        setIsJain(item);
        if (type == 'jain') {
            window.$('#isJainModel').modal('show');
        } else {
            setSauce(null);
            window.$('#AddOnsModel').modal('show');
        }
    }
    const AddTowishlistFunction = (e, id) => {
        var config = {
            method: 'get',
            url: Add_Wishlist_Api + id,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    GetWishlistData();
                    // toast.success(response.data.msg);
                    // Wishlistdata.forEach((i) => {
                    //     let data1 = '';
                    //     if(data.length > 0){
                    //         if (!isEmptyObject(data[0].products)) {
                    //             data1 = data[0].products.map((item, key) => {
                    //                 if (item._id == i.productId) {
                    //                     if(item.islike){
                    //                         item.islike = false;
                    //                     } else {
                    //                         item.islike = true;
                    //                     }
                    //                     return item
                    //                 } else {
                    //                     return item
                    //                 }
                    //             })
                    //         } else {
                    //             data1 = data.map((item, key) => {
                    //                 if (item._id == i.productId) {
                    //                     if(item.islike){
                    //                         item.islike = false;
                    //                     } else {
                    //                         item.islike = true;
                    //                     }
                    //                     return item
                    //                 } else {
                    //                     return item
                    //                 }
                    //             })
                    //         }
                    //     }
                    //     setdata(data1);
                    // })
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        Search_api(searchText);
    }, [searchText])
    const Search_api = (search) => {
        if (search) {
            var config = {
                method: 'get',
                url: Search_Products_Api + search + '/' + nearestOutlet.id,
            };
            axios(config)
                .then((response) => {
                    setdata(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        //  else {
        //     GetData();
        // }
    }
    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setSelectedCheckboxes([...SelectedCheckboxes, value]);
        } else {
            setSelectedCheckboxes(SelectedCheckboxes.filter((item) => item !== value));
        }
    };
    const FilterShortByFunction = () => {
        var config = {
            method: 'post',
            url: Filter_Products_Api,
            data: { sortBy: FilterVallue, category: SelectedCheckboxes, storeId: nearestOutlet.id }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
                $('.filter-modal-close').click();
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const changeQuentity = (e, type) => {
        let id = e.target.id;
        let value = parseInt(e.target.value);
        if (type == 'add') {
            value += 1;
        } else {
            value -= 1;
        }
        var config = {
            method: 'post',
            url: Change_CartProduct_Que_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                id: id,
                quentity: value
            }
        };
        axios(config)
            .then((response) => {
                let data1 = cartData.map(item => {
                    if (item._id == response.data.data._id) {
                        item.quantity = response.data.data.quantity;
                        return item;
                    } else {
                        return item;
                    }
                })
                setCartData(data1);
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        Get_MenuSuperCatagoryData();
    }, [])

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setIsLoad(true);
    //         const $menu = document.querySelector(".menu");
    //         const $items = document.querySelectorAll(".menu--item");
    //         if ($menu && $items.length > 0) {
    //             setIsLoad(false);
    //             const $images = document.querySelectorAll(".menu--item img");
    //             let menuWidth = $menu.clientWidth;
    //             let itemWidth = $items[0].clientWidth;
    //             let wrapWidth = $items.length * itemWidth;

    //             let scrollSpeed = 0;
    //             let oldScrollY = 0;
    //             let scrollY = 0;
    //             let y = 0;
    //             const lerp = (v0, v1, t) => {
    //                 return v0 * (1 - t) + v1 * t;
    //             };
    //             const dispose = scroll => {
    //                 gsap.set($items, {
    //                     x: i => {
    //                         return i * itemWidth + scroll;
    //                     },
    //                     modifiers: {
    //                         x: (x, target) => {
    //                             const s = gsap.utils.wrap(
    //                                 -itemWidth,
    //                                 wrapWidth - itemWidth,
    //                                 parseInt(x));

    //                             return `${s}px`;
    //                         }
    //                     }
    //                 });
    //             };
    //             dispose(0);

    //             // Previous button
    //             const prevButton = document.getElementById('prevButton');
    //             prevButton.addEventListener('click', () => {
    //                 scrollY += itemWidth; // Adjust the scroll position
    //             });

    //             // Forward button
    //             const forwardButton = document.getElementById('forwardButton');
    //             forwardButton.addEventListener('click', () => {
    //                 scrollY -= itemWidth; // Adjust the scroll position
    //             });

    //             const handleMouseWheel = e => {
    //                 scrollY -= e.deltaY * 0.9;
    //             };
    //             let touchStart = 0;
    //             let touchX = 0;
    //             let isDragging = false;
    //             const handleTouchStart = e => {
    //                 touchStart = e.clientX || e.touches[0].clientX;
    //                 isDragging = true;
    //                 $menu.classList.add("is-dragging");
    //             };
    //             const handleTouchMove = e => {
    //                 if (!isDragging) return;
    //                 touchX = e.clientX || e.touches[0].clientX;
    //                 scrollY += (touchX - touchStart) * 1.5;
    //                 touchStart = touchX;
    //             };
    //             const handleTouchEnd = () => {
    //                 isDragging = false;
    //                 $menu.classList.remove("is-dragging");
    //             };
    //             $menu.addEventListener("mousewheel", handleMouseWheel);

    //             $menu.addEventListener("touchstart", handleTouchStart);
    //             $menu.addEventListener("touchmove", handleTouchMove);
    //             $menu.addEventListener("touchend", handleTouchEnd);

    //             $menu.addEventListener("mousedown", handleTouchStart);
    //             $menu.addEventListener("mousemove", handleTouchMove);
    //             $menu.addEventListener("mouseleave", handleTouchEnd);
    //             $menu.addEventListener("mouseup", handleTouchEnd);

    //             $menu.addEventListener("selectstart", () => {
    //                 return false;
    //             });
    //             window.addEventListener("resize", () => {
    //                 menuWidth = $menu.clientWidth;
    //                 itemWidth = $items[0].clientWidth;
    //                 wrapWidth = $items.length * itemWidth;
    //             });

    //             const render = () => {
    //                 requestAnimationFrame(render);
    //                 y = lerp(y, scrollY, 0.1);
    //                 dispose(y);
    //                 scrollSpeed = y - oldScrollY;
    //                 oldScrollY = y;
    //             };
    //             render();
    //             clearInterval(intervalId);
    //         }
    //     }, 1000);
    //     return () => clearInterval(intervalId);
    // }, [SliderData]);

    const delteProductCart = (e) => {
        let id = e.target.id;
        var config = {
            method: 'delete',
            url: Delete_Product_Cart_Api,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id }
        };
        axios(config)
            .then((response) => {
                let data = cartData.filter(x => x._id != id);
                setCartData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            {
                IsLoad == true || MenuSuperCatagoryData.length < 0 ||
                    AllCatagorydata.length <= 0 || IsLoad == true ? <Loader /> :
                    <>
                        {!isEmptyObject(nearestOutlet) ? <Header fileName={'product'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet} setSearchTextParams={setSearchText} cartCount={cartData.length} /> : <Header fileName={'product'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet} setSearchTextParams={setSearchText} cartCount={cartData.length} />}
                        <div className="order">
                            <section className="menu-sec">
                                {/* <div className="menu-bar">
                                    <div className="container d-flex align-items-center justify-content-end py-2">
                                        <div className="tab-container col-9 scroll">
                                            <div className={`tab col ${activeClass === 'All' ? 'active' : ''}`} style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setactiveClass('All'); GetData();
                                                    // setactiveCatgaory('');
                                                }}>All</div>
                                            {MenuSuperCatagoryData.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className={`tab col ${activeClass === item.superCategoryName ? 'active' : ''}`} style={{ cursor: "pointer" }}
                                                            key={index}><p onClick={(e) => {
                                                                if (activeClass != item.superCategoryName) {
                                                                    setactiveClass(item.superCategoryName)
                                                                    GetSuperCatagoryWiseData(item._id)
                                                                } else {
                                                                    setactiveClass('All');
                                                                    // setactiveCatgaory('');
                                                                    GetData();
                                                                }
                                                            }}>{item.superCategoryName}</p>
                                                        </div >
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div className="col-3">
                                            <input type="text" onChange={(e) => Search_api(e)} className="form-control search" name='Search' placeholder="Search" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg" >
                                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel">Filters</h5>
                                                <button type="button" className="btn-close filter-modal-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-3 col-12">
                                                        <ul className="filter-tab-container nav nav-tabs">
                                                            <li>
                                                                <a className="tab pro filter-nav-link active" data-bs-toggle="tab" href="#Short-By">Short By</a>
                                                            </li>
                                                            <li>
                                                                <a className="tab Cuisines filter-nav-link " data-bs-toggle="tab" href="#Cuisines">Cuisines</a>
                                                            </li>
                                                            <li>
                                                                <a className="tab More filters filter-nav-link" data-bs-toggle="tab" href="#More-filters">More filters</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-9 col-12 mt-5 my-md-0 tab-content">

                                                        <div className="tab-pane Short-By ms-md-4 active" id='Short-By'>
                                                            <div className="col-md-12">
                                                                <div className="titles">
                                                                    <h5>Short By</h5>
                                                                    <ul className='short-by-filter-ul'>
                                                                        <li>
                                                                            <input type="radio" id="1" value="1" name="selector" checked={FilterVallue == '1' ? true : false} onChange={(e) => { setFilterVallue(e.target.value) }} />
                                                                            <label htmlFor="1">Popularity</label>
                                                                            <div className="check"></div>
                                                                        </li>

                                                                        <li>
                                                                            <input type="radio" id="2" value="2" name="selector" checked={FilterVallue == '2' ? true : false} onChange={(e) => { setFilterVallue(e.target.value) }} />
                                                                            <label htmlFor="2">Rating: High to Low</label>
                                                                            <div className="check"><div className="inside"></div></div>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="3" value="3" name="selector" checked={FilterVallue == '3' ? true : false} onChange={(e) => { setFilterVallue(e.target.value) }} />
                                                                            <label htmlFor="3">Cost: Low to High</label>
                                                                            <div className="check"><div className="inside"></div></div>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="4" value="4" name="selector" checked={FilterVallue == '4' ? true : false} onChange={(e) => { setFilterVallue(e.target.value) }} />
                                                                            <label htmlFor="4">Cost: High to Low</label>
                                                                            <div className="check"><div className="inside"></div></div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="tab-pane Cuisines" id='Cuisines'>
                                                            <div className="titles">
                                                                <h5>Cuisines</h5>
                                                                <ul className='Cuisines-filter-container mt-3'>
                                                                    {AllCatagorydata.map((item, index) => {
                                                                        return (
                                                                            <li className='col-6' key={index}>
                                                                                <label htmlFor="selected_cuisines">
                                                                                    <input type="checkbox" id={item.name} name="selected_cuisines[]" value={item._id} checked={SelectedCheckboxes.includes(item._id)} onChange={(e) => handleCheckboxChange(e)} />
                                                                                    {item.name}
                                                                                </label>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="tab-pane More-filters ms-md-4" id='More-filters'>
                                                            <div className="titles">
                                                                <h5>More filters</h5>
                                                                <div className='col-12 mt-3'>
                                                                    <input type="text" onChange={(e) => Search_api(e.target.value)} className="form-control search" name='Search' placeholder="Search" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { setSelectedCheckboxes([]); GetData() }}>Clear All</button>
                                                <button type="button" className="btn btn-primary" onClick={() => FilterShortByFunction()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="home">
                                    <div className="container py-5">
                                        <div className="heading">
                                            <div className='d-flex justify-content-between'>
                                                <h4>What's on your mind?</h4>
                                                <div className='custom-slider-btn d-flex'>
                                                    <button id="prevButton" className="slider-btn me-2" onClick={() => document.getElementById('menu').scrollBy({ left: -200, behavior: 'smooth' })}>
                                                        <span>
                                                            <i className="bi bi-arrow-left"></i>
                                                        </span>
                                                    </button>
                                                    <button id="forwardButton" className="slider-btn" onClick={() => document.getElementById('menu').scrollBy({ left: 200, behavior: 'smooth' })}>
                                                        <span>
                                                            <i className="bi bi-arrow-right"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>                            
                                            <div className="slider-body">
                                                <div className="menu" id="menu">
                                                    <div className="menu--wrapper w-100">
                                                        {SliderData.map((item, index) => {
                                                            return (
                                                                <div className="menu--item text-center" onMouseEnter={() => setSliderHover(item)} onMouseLeave={() => setSliderHover()} onClick={() => {
                                                                    GetSuperCatagoryWiseData(item.superCategory_id);
                                                                    setactiveClass(item.SuperCategory.superCategoryName);
                                                                }} key={index}>
                                                                    <div style={{ width: SliderHover == item ? '135px' : '125px', height: SliderHover == item ? '135px' : '125px' }}>
                                                                        <div className='slider-image-div'>
                                                                            <img src={item.image} alt="Image" style={{
                                                                            boxShadow: activeClass === item.SuperCategory.superCategoryName ? '0 0 15px #ffb81c' : '',
                                                                            border: activeClass === item.SuperCategory.superCategoryName ? '5px solid #ffb81c' : ''
                                                                    }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-dark mt-2' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                                        <p style={{ color: activeClass === item.SuperCategory.superCategoryName ? '#ffb81c' : '' }}>{item.SuperCategory.superCategoryName}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className='filter-main-container mb-3'>
                                        <div className="Filters-btn">
                                            <button className='d-flex' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                <img src='../../assets/images/filter.png' style={{ width: "15px" }} className='me-2' />
                                                Filters
                                            </button>
                                        </div>
                                    </div>
                                    <div className="title">
                                        {/* <h4>{activeClass} {activeCatgaory ? ` > ${activeCatgaory}` : ''}</h4>
                                        <h6>{data.length} Items found</h6> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-8 col-xl-9 product-section-wrapper">
                                            <div className="row">
                                                {data.length > 0 ? data.map((item, index) => {
                                                    indexes += 1;
                                                    if (!isEmptyObject(item.products)) {
                                                        return (
                                                            <>
                                                                <div className="title" key={indexes}>
                                                                    <h4>{item._id ? item._id : ''}</h4>
                                                                    <h6>{item.products.length} Items found</h6>
                                                                </div>
                                                                <div className="row" key={indexes}>
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            {item.products.length > 0 ? item.products.map((product, productIndex) => {
                                                                                indexes += 1;
                                                                                return (
                                                                                    <div className="col-12 col-md-6 col-lg-6 col-xl-4" style={{ paddingBottom: "30px" }} key={indexes}>
                                                                                        <div className="item-box">
                                                                                            <div className="pic">
                                                                                                <img src={product.previewImage ? product.previewImage : "../../assets/images/default_img.jpeg"} className="w-100" />
                                                                                            </div>
                                                                                            <div className="veg">
                                                                                                {product.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />}
                                                                                            </div>
                                                                                            <div className="like" style={{ cursor: 'pointer' }} id={product._id} onClick={(e) => {
                                                                                                AddTowishlistFunction(e, product._id);
                                                                                            }}>
                                                                                                {product.islike ?
                                                                                                    <img src="../../assets/images/like.png" /> : <img src="../../assets/images/unlike.png" />}
                                                                                            </div>
                                                                                            <div className="detail-box">
                                                                                                <div className="price">
                                                                                                    <h5>₹{product.rate}</h5>
                                                                                                </div>
                                                                                                <h5>{product.name}</h5>
                                                                                                <p style={{ maxHeight: "150px" }}>
                                                                                                    {product.description.length > 0 ? product.description.split(' ').slice(0, 20).join(' ') : product.aliasDescription.length > 0 ? product.aliasDescription.split(' ').slice(0, 20).join(' ') : product.aliasDescription}
                                                                                                </p>
                                                                                                <div className="add-cart justify-content-between">
                                                                                                    <div className='col-7'>
                                                                                                        <button className="cart" onClick={(e) => { product.addons.length > 0 ? selectJainModel(product, 'addOns') : AddToCartFunction(e, product._id) }}>Add to cart</button>
                                                                                                    </div>
                                                                                                    {
                                                                                                        product.isJain ?
                                                                                                            <div className="form-floating col-5">
                                                                                                                <select name={`isJain_${product._id}`} defaultValue={'false'} className='form-control' id="floatingInput">
                                                                                                                    <option value="false">Regular</option>
                                                                                                                    <option value="true">Jain</option>
                                                                                                                </select>
                                                                                                                <label htmlFor="floatingInput">Type</label>
                                                                                                            </div>
                                                                                                            : ''
                                                                                                    }
                                                                                                    {/* <a className="btn order-btn text-dark" href='/cart'>Order now</a> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) : <div>No Products Avilable</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4" style={{ paddingBottom: "30px" }} key={indexes}>
                                                                <div className="item-box">
                                                                    <div className="pic">
                                                                        <img src={item.previewImage ? item.previewImage : "../../assets/images/default_img.jpeg"} className="w-100" />
                                                                    </div>
                                                                    <div className="veg">
                                                                        {item.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />}
                                                                    </div>
                                                                    <div className="like" style={{ cursor: 'pointer' }} id={item._id} onClick={(e) => {
                                                                        AddTowishlistFunction(e, item._id);
                                                                    }}>
                                                                        {item.islike ?
                                                                            <img src="../../assets/images/like.png" /> : <img src="../../assets/images/unlike.png" />}
                                                                    </div>
                                                                    <div className="detail-box">
                                                                        <div className="price">
                                                                            <h5>₹{item.rate}</h5>
                                                                        </div>
                                                                        <h5>{item.name}</h5>
                                                                        <p style={{ maxHeight: "150px" }}>
                                                                            {/* {item.description} */}
                                                                            {item.description.length > 0 ? item.description.split(' ').slice(0, 20).join(' ') : item.aliasDescription.length > 0 ? item.aliasDescription.split(' ').slice(0, 20).join(' ') : item.aliasDescription}
                                                                        </p>
                                                                        <div className="add-cart justify-content-between align-items-center">
                                                                            <div className='col-7'>
                                                                                <button className="cart" onClick={(e) => { item.addons.length > 0 ? selectJainModel(item, 'addOns') : AddToCartFunction(e, item._id) }}>Add to cart</button>
                                                                            </div>
                                                                            {
                                                                                item.isJain ?
                                                                                    <div className="form-floating col-5">
                                                                                        <select name={`isJain_${item._id}`} defaultValue={'false'} className='form-control' id="floatingInput">
                                                                                            <option value="false">Regular</option>
                                                                                            <option value="true">Jain</option>
                                                                                        </select>
                                                                                        <label htmlFor="floatingInput">Type</label>
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }) : <div>No Products Avilable</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-xl-3 d-none d-lg-block">
                                            <div className="cart-section" style={{ float: 'right' }}>
                                                {/* {data.length > 0 ? !isEmptyObject(data[0].products) ?
                                                    <div className="title w-100" style={{ height: '71px' }}>
                                                    </div> : '' : ''
                                                } */}
                                                {
                                                    cartData.length > 0 ?
                                                        <div className="cart-box p-3 col-12" style={{ borderRadius: '16px 16px 0 0', paddingTop: '71px' }}>
                                                            {cartData.map((item, index) => {
                                                                subTotal += (item.product.rate * item.quantity)
                                                                return (
                                                                    <>
                                                                        <div className='mb-3' style={{ borderBottom: '1px dotted #00000045' }} key={index} >
                                                                            <div className='row'>
                                                                                <div className='col-3 pe-0 position-relative'>
                                                                                    <img src={item.product.previewImage ? item.product.previewImage : "../../assets/images/test.jpg"} className='w-100 rounded p-0' />

                                                                                    <div className="veg position-absolute" style={{ top: '5%', right: '60%' }}>
                                                                                        {item.product.isNonVeg == true ? <img src="../../assets/images/non-veg.png" className='p-0' style={{ width: '85%' }} /> : <img src="../../assets/images/veg.png" className='p-0' style={{ width: '85%' }} />}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-9'>
                                                                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>{item.product.name != '' ? item.product.name : item.product.aliasName}</h5>

                                                                                    <p style={{ margin: '5px 0', fontSize: '12px' }}>{item.product.description.length > 0 ? item.product.description.split(' ').slice(0, 20).join(' ') : item.product.aliasDescription.length > 0 ? item.product.aliasDescription.split(' ').slice(0, 20).join(' ') : item.product.aliasDescription}</p>

                                                                                    <p>Type: <span style={{ color: 'green', fontWeight: 500 }}>{item.isJain ? 'Jain' : 'Regular'}</span></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex my-3 align-items-center'>
                                                                                <div className='col-4 d-flex'>
                                                                                    <button className='cartBtn decBtn' type="button" onClick={(e) => changeQuentity(e, 'remove')} id={item._id} value={item.quantity} disabled={item.quantity == 1 ? 'disabled' : ''} name="button">-</button>
                                                                                    <input type="number" name="inputQty" id="" value={item.quantity} className='cartBtn inputQty' disabled />
                                                                                    <button className='cartBtn incBtn' type="button" name="button" onClick={(e) => changeQuentity(e, 'add')} value={item.quantity} id={item._id}>+</button>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <button className="order-btn text-dark" id={item._id} onClick={(e) => delteProductCart(e)} style={{ backgroundColor: '#ffb81c' }}>Remove</button>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <p style={{ float: 'right' }}>₹{item.product.rate * item.quantity}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                        :
                                                        <div className="cart-box col-12">
                                                            <img src="../../assets/images/no-cart.png" />
                                                            <h4>Your cart is <br />empty</h4>
                                                            <p>Please add some items from the menu.</p>
                                                        </div>
                                                }
                                                {
                                                    cartData.length > 0 ?
                                                        <div className='d-flex col-12 p-3' style={{ borderRadius: '0 0 16px 16px', boxShadow: '0px 0px 5px 0px #00000017' }}>
                                                            <div className='col-6'>
                                                                <p style={{ fontWeight: 700, color: '#00000082', marginBottom: '5px' }}>Sub Total</p>
                                                                <p style={{ fontWeight: 'bolder' }}>₹{subTotal}</p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <button className='cart w-100' onClick={() => navigate('/cart')}>Check Out</button>
                                                            </div>
                                                        </div> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </div >
                        <Footer />
                        <div className="modal fade" id="isJainModel" tabIndex="-1" aria-labelledby="isJainModelLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div>
                                            <h1 className="modal-title fs-5" id="isJainModelLabel">{isJain.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />} {isJain.name}</h1>
                                            <p style={{ paddingLeft: '20px' }} >₹{isJain.rate}</p>
                                        </div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From Preparation Style for {isJain.name} (optional)</p>
                                        <div className='checkbox text-start mt-4 mb-2'>
                                            <img src={isJain.isNonVeg == true ? "../../assets/images/non-veg.png" : "../../assets/images/veg.png"} />
                                            <input type="checkbox" name="isJain" id="isJain" />
                                            <label htmlFor="isJain" style={{ fontSize: '14px', marginLeft: '10px' }}>Jain</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="w-100 cart" onClick={(e) => AddToCartFunction(e, isJain._id, $("#isJain").prop('checked'))}>Add To Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="AddOnsModel" tabIndex="-1" aria-labelledby="AddOnsModelLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div>
                                            <h1 className="modal-title fs-5" id="AddOnsModelLabel">{isJain.isNonVeg == true ? <img src="../../assets/images/non-veg.png" /> : <img src="../../assets/images/veg.png" />} {isJain.name}</h1>
                                            <p style={{ paddingLeft: '20px' }} >₹{isJain.rate}</p>
                                        </div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From {isJain.addonsCategory ? isJain.addonsCategory.name : ''} for {isJain.name} (atleast {isJain.addonsCategory ? isJain.addonsCategory.minNoOfAddon : 1})</p>
                                        {
                                            isJain.addons && isJain.addons.map((item, index) => {
                                                return (
                                                    <div className='checkbox text-start mt-4 mb-2' key={index}>
                                                        <img src={isJain.isNonVeg == true ? "../../assets/images/non-veg.png" : "../../assets/images/veg.png"} />
                                                        <input type="checkbox" name={item.name == '' ? item.aliasName : item.name} id={item.name == '' ? item.aliasName : item.name} value={item._id} checked={sauce == (item._id)} onChange={(e) => { setSauce(e.target.value); $(".sauceSelect").addClass('d-none'); }} />
                                                        <label htmlFor={item.name == '' ? item.aliasName : item.name} style={{ fontSize: '14px', marginLeft: '10px' }}>{item.name == '' ? item.aliasName : item.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                        <p className="text-danger sauceSelect d-none"> select atleast 1 sauce </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="w-100 cart" onClick={(e) => { sauce != null ? AddToCartFunction(e, isJain._id, false) : $('.sauceSelect').removeClass('d-none') }}>Add To Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </>
    )
}

export default HomePage